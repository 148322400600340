import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { Link } from "react-router-dom";

import { Button } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';

import { useStyles } from './styles';
import { useTheme } from "@material-ui/core";

export default function UserMenu() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [smallDevice, setSmallDevice] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if(window.innerWidth <= 600){
      setSmallDevice(true);
    }

    window.addEventListener('resize', () => {
      if(window.innerWidth <= 600){
        setSmallDevice(true);
      }
      else{
        setSmallDevice(false);
      }
    });
  }, []);
  return (
    <>
    <AppBar
      position="static"
      className={clsx(classes.appBar, classes.menu, {
      [classes.appBarShift]: open,
      })}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          EduLibras
        </Typography>
        {!smallDevice && (
          <>
          <Button color="inherit" component={Link} to="/modulos">
          Módulos
          </Button>
          <Button color="inherit" component={Link} to="/dicionario">
            Dicionário
          </Button>
          <Button color="inherit" component={Link} to="/biblioteca">
            Biblioteca
          </Button>
          <Button color="inherit" component={Link} to="/perfil">
            Perfil
          </Button>
          </>
        )}

        {smallDevice &&
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        }

      </Toolbar>
    </AppBar>
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <List>
        <ListItem button component={Link} to="/modulos">
          Módulos
        </ListItem>
        <ListItem button component={Link} to="/dicionario">
          Dicionário
        </ListItem>
        <ListItem button component={Link} to="/biblioteca">
          Biblioteca
        </ListItem>
        <ListItem button component={Link} to="/perfil">
          Perfil
        </ListItem>
      </List>
    </Drawer>
    </>
  );
}
