import { TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../../components/Footer";
import UserMenu from "../../components/UserMenu";
import firebase from "../../Services/firebase";
import firebaseApp from "firebase/app";
import { toast } from "react-toastify";
import { translateAuthErrors } from "../../utils/translateErrorUtils";
import "./styles.css";

const VincularEmailSenha = () => {
  const history = useHistory();
  const userFirebase = firebase.getUser();
  const user = {
    email: "",
    password: "",
  };
  const [password, setPassword] = useState(user.email);
  const [email, setEmail] = useState(user.password);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const credential = firebaseApp.auth.EmailAuthProvider.credential(
        email,
        password
      );
      userFirebase
        .linkWithCredential(credential)
        .then(() => {
          toast.success("Email e senha vinculado ao seu perfil com sucesso.", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          history.push("/perfil");
        })
        .catch((error) => {
          const errorMessage = translateAuthErrors(error);
          toast.error(`${errorMessage}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    },
    [email, password, history, userFirebase]
  );

  useEffect(() => {});

  return (
    <div className="container editar-perfil-container">
      <UserMenu />
      <div className="content">
        <h2>Vincular Email e Senha</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            type="email"
            name="email"
            label="E-mail"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            fullWidth
            required
          />

          <TextField
            type="password"
            name="password"
            label="Senha"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <button type="submit" className="btn-default">
            Vincular
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default VincularEmailSenha;
