import React, { useCallback } from "react";
import { Link, withRouter } from "react-router-dom";

import firebase from "../../Services/firebase";

import "./styles.css";

import logoImg from "../../assets/logo_branca.svg";
import { TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import { translateAuthErrors } from "../../utils/translateErrorUtils";

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;

      try {
        await firebase.login(email.value, password.value);
        history.push("/");
      } catch (error) {
        const errorMessage = translateAuthErrors(error);
        toast.error(`${errorMessage}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [history]
  );

  return (
    <div className="container login-container">
      <Link to="/">
        <img src={logoImg} alt="EduLibras" className="logo" />
      </Link>
      <div className="content">
        <h2>Entrar</h2>
        <form onSubmit={handleLogin}>
          <TextField
            type="email"
            name="email"
            label="E-mail"
            fullWidth
            required
          />
          <TextField
            type="password"
            name="password"
            label="Senha"
            fullWidth
            required
          />
          <button type="submit" className="btn-default">
            Entrar
          </button>
        </form>
        <Link to="/recuperar-senha">Esqueci minha senha.</Link>
      </div>
      <Link to="/acesso">Voltar</Link>
    </div>
  );
};

export default withRouter(Login);
