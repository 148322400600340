export function translateAuthErrors(error) {
  const codeError = error.code;
  let errorMessage = "";
  switch (codeError) {
    case "auth/account-exists-with-different-credential":
      errorMessage =
        "Já existe uma conta com o mesmo endereço de email, mas com credenciais de login diferentes." +
        " Faça login usando um provedor associado a este endereço de e-mail.";
      break;
    case "auth/invalid-credential":
      errorMessage = "Credencial de acesso incorreta.";
      break;
    case "auth/operation-not-allowed":
      errorMessage =
        "Provedor de Login não está ativo. Tente outro meio de acesso.";
      break;
    case "auth/user-disabled":
      errorMessage = "Esta credencial foi desativada.";
      break;
    case "auth/user-not-found":
      errorMessage = "Usuário não encontrado";
      break;
    case "auth/wrong-password":
      errorMessage = "Senha de acesso incorreta.";
      break;
    case "auth/invalid-verification-code":
      errorMessage = "Código de verificação de credencial inválido.";
      break;
    case "auth/invalid-verification-id":
      errorMessage = "Id de credencial e código não válido.";
      break;
    case "auth/expired-action-code":
      errorMessage = "Código de redefinição de senha expirado.";
      break;
    case "auth/invalid-action-code":
      errorMessage = "Código de redefinição de senha inválido.";
      break;
    case "auth/weak-password":
      errorMessage = "Senha não é forte o suficiente";
      break;
    case "auth/email-already-in-use":
      errorMessage =
        "Já existe uma conta cadastrada com este endereço de email.";
      break;
    case "auth/invalid-email":
      errorMessage = "Email inválido";
      break;
    case "auth/missing-android-pkg-name":
      errorMessage =
        "Pacote de aplicativo não especificado. Contate o suporte.";
      break;
    case "auth/missing-continue-uri":
      errorMessage =
        "Endereço de provedor não especificado. Contate o suporte.";
      break;
    case "auth/missing-ios-bundle-id":
      errorMessage =
        "Bundle ID de aplicativo não especificado. Contate o suporte.";
      break;
    case "auth/invalid-continue-uri ":
      errorMessage = "Endereço de provedor incorreto. Contato o suporte.";
      break;
    case "auth/unauthorized-continue-uri":
      errorMessage = "Endereço de provedor não autorizado. Contate o Suporte.";
      break;
    case "auth/auth-domain-config-required":
      errorMessage =
        "Endereço de autenticação não foi inicializado. Contate o suporte.";
      break;
    case "auth/cancelled-popup-request":
      errorMessage = "Janela de requisição encerrada.";
      break;
    case "auth/operation-not-supported-in-this-environment":
      errorMessage =
        "Operação não é suportada pelo ambiente da aplicação. Contate o suporte.";
      break;
    case "auth/popup-blocked":
      errorMessage = "Janela de requisição bloqueada. ";
      break;
    case "auth/popup-closed-by-user":
      errorMessage = "Janela de requisição encerrada pelo usuário.";
      break;
    case "auth/unauthorized-domain":
      errorMessage = "Domínio de autenticação não autorizado.";
      break;
    case "auth/requires-recent-login":
      errorMessage =
        "Alterar senha requer login recente. Acesse novamente sua conta e tente novamente.";
      break;
    case "auth/provider-already-linked":
      errorMessage =
        "Esse provedor já está vinculado.";
      break;
    default:
      errorMessage = "Erro não especificado";
      break;
  }

  return errorMessage;
}
