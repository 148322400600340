import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import UserMenu from "../../components/UserMenu";
import Footer from "../../components/Footer";
import Video from "../Partials/Video";

import api from "../../Services/api";
import firebase from "../../Services/firebase";

import { useParams } from "react-router-dom";

import "./styles.css";

export default function Modulo(props) {
  const { id: idModulo } = useParams();

  const [modulo, setModulo] = useState("");
  const [atividades, setAtividades] = useState([]);

  useEffect(() => {
    api.get("modules/" + idModulo).then(response => {
      setModulo(response.data);
    });

    api
      .get("activities/all/" + idModulo)
      .then(response => setAtividades(response.data));

  }, [idModulo]);

  function PDFModulo() {
    //TODO: abrir PDF em modal
    if (modulo.pdf !== undefined && modulo.pdf !== "undefined") {
      return (
        <a className="btn-default"
          href={`${modulo.pdf}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Acesse o livro
        </a>
      );
    }
    return "";
  }

  function ImagemModulo() {
    if (modulo.image !== undefined && modulo.image !== "undefined") {
      return <img src={`${modulo.image}`} alt="" />;
    }
    return "";
  }

  const refazerModulo = () => {
    firebase.database.ref(`respostas-corretas/${firebase.getUser().uid}/modulos/${atividades[0].module}`).remove();
  }


  return (
    <div className="container">
      <UserMenu />
      <div className="content">
        <div className="modulo-container">
          <h2>Módulo: {modulo.name}</h2>
          <h3>{modulo.description}</h3>
          <ImagemModulo />
          <Video video={modulo.video} />
          <PDFModulo />
          <button className="btn-default" onClick={refazerModulo}>Refazer Módulo</button>
          <section>
            <h3>Atividades ({atividades.length})</h3>
            <ol>
              {atividades.map(atividade => (
                <li key={atividade._id}>
                  <Link to={`atividade/${atividade._id}`}>
                    {atividade.statement}
                  </Link>
                </li>
              ))}
            </ol>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}
