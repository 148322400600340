import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "./styles.css";

import api from "../../Services/api";

import UserMenu from "../../components/UserMenu";
import Footer from "../../components/Footer";

export default function Biblioteca() {
  const [tipoItem, setTipoItem] = useState("");

  const [videoClasses, setVideoClasses] = useState("");
  const [musicClasses, setMusicClasses] = useState("");
  const [bookClasses, setBookClasses] = useState("");

  const [videos, setVideos] = useState([]);
  const [musics, setMusics] = useState([]);
  const [books, setBooks] = useState([]);

  const [videosFiltered, setVideosFiltered] = useState([]);
  const [musicsFiltered, setMusicsFiltered] = useState([]);
  const [booksFiltered, setBooksFiltered] = useState([]);

  useEffect(() => {
    api.get("library/view/video").then((response) => {
      let list = response.data;
      list.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setVideos(list);
      setVideosFiltered(list);
    });

    api.get("library/view/music").then((response) => {
      let list = response.data;
      list.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setMusics(list);
      setMusicsFiltered(list);
    });

    api.get("library/view/book").then((response) => {
      let list = response.data;
      list.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setBooks(list);
      setBooksFiltered(list);
    });
  }, []);

  const [search, setSearch] = useState("");


  useEffect(() => {
    const lastLibState = localStorage.getItem('lastLibFilter');
    setTipoItem(lastLibState);

    switch (tipoItem) {
      case "Vídeos":
        setVideoClasses("active");
        setMusicClasses("");
        setBookClasses("");
        break;
      case "Músicas":
        setVideoClasses("");
        setMusicClasses("active");
        setBookClasses("");
        break;
      case "Livros":
        setVideoClasses("");
        setMusicClasses("");
        setBookClasses("active");
        break;
      default:
        break;
    }

  }, [tipoItem]);

  useEffect(() => {
    setVideosFiltered(
      videos.filter((video) =>
        video.name.toUpperCase().includes(search.toUpperCase())
      )
    );

    setMusicsFiltered(
      musics.filter((music) =>
        music.name.toUpperCase().includes(search.toUpperCase())
      )
    );

    setBooksFiltered(
      books.filter((book) =>
        book.name.toUpperCase().includes(search.toUpperCase())
      )
    );
  }, [search, musics, videos, books]);

  const changeFilter = (typeFilter) => {
    const lastLibState = localStorage.getItem('lastLibFilter');
    if (lastLibState != tipoItem) {
      localStorage.setItem('lastLibFilter', typeFilter);
    }
  }

  function ShowList() {
    switch (tipoItem) {
      case "Vídeos":
        return (
          <div className="videos-container">
            {videosFiltered.map((video) => (
              <div className="biblioteca-item" key={video._id}>
                <h3>
                  <Link to={`/biblioteca/${video._id}`} onClick={changeFilter('Vídeos')}>{video.name}</Link>
                </h3>
              </div>
            ))}
          </div>
        );
      case "Músicas":
        return (
          <div className="videos-container">
            {musicsFiltered.map((music) => (
              <div className="biblioteca-item" key={music._id}>
                <h3>
                  <Link to={`/biblioteca/${music._id}`} onClick={changeFilter('Músicas')}>{music.name}</Link>
                </h3>
              </div>
            ))}
          </div>
        );
      case "Livros":
        return (
          <div className="videos-container">
            {booksFiltered.map((book) => (
              <div className="biblioteca-item" key={book._id}>
                <h3>
                  <Link to={`/biblioteca/${book._id}`} onClick={changeFilter('Livros')}>{book.name}</Link>
                </h3>
              </div>
            ))}
          </div>
        );
      default:
        return "";
    }
  }

  function handleClick(e) {
    setTipoItem(e.target.value);
  }

  function handleSearch(e) {
    e.preventDefault();
    setSearch(e.target.value);
  }
  return (
    <div className="container">
      <UserMenu />
      <div className="content">
        <div className="biblioteca-container">
          <h2>Biblioteca</h2>

          <div className="tab">
            <button
              className={`tablinks ${videoClasses}`}
              value="Vídeos"
              onClick={handleClick}
            >
              Vídeos
            </button>
            <button
              className={`tablinks ${musicClasses}`}
              value="Músicas"
              onClick={handleClick}
            >
              Musicas
            </button>
            <button
              className={`tablinks ${bookClasses}`}
              value="Livros"
              onClick={handleClick}
            >
              Livros
            </button>
          </div>
          <div className="biblioteca-item">
            <input
              type="text"
              placeholder="Pesquisar"
              value={search}
              onChange={handleSearch}
            />
            <ShowList />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
