import React, { useCallback, useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { TextField } from "@material-ui/core";

import "./styles.css";

import logoImg from "../../assets/logo_branca.svg";
import rebecaImg from "../../assets/Rebeca_3x4.png";
import eduImg from "../../assets/Edu_3x4.png";
import firebase from "../../Services/firebase";
import { toast } from "react-toastify";
import { translateAuthErrors } from "../../utils/translateErrorUtils";

const Register = () => {
  const history = useHistory();
  const [avatar, setAvatar] = useState("edu");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eduClass, setEduClass] = useState("selected");
  const [rebecaClass, setRebecaClass] = useState("");

  const handleSignUp = useCallback(
    async (event) => {
      event.preventDefault();
      console.log(avatar);

      try {
        await firebase.register(name, email, password, avatar);
        history.push("/");
      } catch (error) {
        const errorMessage = translateAuthErrors(error);
        toast.error(`${errorMessage}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [history, name, email, password, avatar]
  );

  useEffect(() => {
    if (avatar === "edu") {
      setEduClass("selected");
      setRebecaClass("");
    } else {
      setEduClass("");
      setRebecaClass("selected");
    }
  }, [avatar]);

  return (
    <div className="register-container">
      <Link to="/">
        <img src={logoImg} alt="EduLibras" className="logo" />
      </Link>
      <div className="content">
        <h2>Cadastrar</h2>
        <form onSubmit={handleSignUp}>
          <input
            type="radio"
            id="eduAvatar"
            name="avatar"
            value="edu"
            className="hidden"
          />
          <input
            type="radio"
            id="rebecaAvatar"
            name="avatar"
            value="rebeca"
            className="hidden"
          />

          <div className="profile-picture-box">
            <label
              htmlFor="eduAvatar"
              className={eduClass}
              onClick={(event) => {
                setAvatar("edu");
              }}
            >
              <img src={eduImg} alt="" className="user-img edu" />
            </label>
            <label
              htmlFor="rebecaAvatar"
              className={rebecaClass}
              onClick={(event) => {
                setAvatar("rebeca");
              }}
            >
              <img src={rebecaImg} alt="" className="user-img" />
            </label>
          </div>

          <TextField
            type="text"
            name="name"
            label="Nome"
            value={name}
            onChange={(event) => setName(event.target.value)}
            fullWidth
            required
          />

          <TextField
            type="email"
            name="email"
            label="E-mail"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            fullWidth
            required
          />

          <TextField
            type="password"
            name="password"
            label="Senha"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
            required
          />

          <button type="submit" className="btn-default">
            Cadastrar
          </button>
        </form>
      </div>
      <Link to="/acesso">Voltar</Link>
    </div>
  );
};

export default withRouter(Register);
