import React from 'react';
import { Route as ReactDomRoute,  Redirect } from 'react-router-dom';
import firebase from '../Services/firebase';

export default function Route ({
  isPrivate = false,
  component: Component,
  ...rest
}) {
  const user = firebase.getUser();

  return (
    <ReactDomRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect to={{
            pathname: isPrivate ? '/acesso' : '/modulos',
            state: { from: location },
          }} />
        )
      }} />
  );
}
