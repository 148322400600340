import React from "react";

import "./styles.css";

import AtividadeDiscursiva from "../AtividadeDiscursiva";
import AtividadeObjetiva from "../AtividadeObjetiva";

export default function Atividade(props) {
  const { atividade, mostrarRespostas} = props;

  return (
    <div className="atividade-container">
      {atividade.type === "obj"
        ? <AtividadeObjetiva atividade={atividade} mostrarRespostas={mostrarRespostas}/>
        : <AtividadeDiscursiva atividade={atividade} mostrarRespostas={mostrarRespostas}/>
      }
    </div>
  );
}
