import React, { useState, useEffect } from "react";
import firebase from "../../Services/firebase";

import "./styles.css";

import iconeLibras from "../../assets/logo_DU.svg";

export default function Alternativa(props) {
  const { atividade, alternativa, checked, setSelectedOption, mostrarRespostas } = props;
  const [classes, setClasses] = useState("");
  const [message, setMessage] = useState("");


  useEffect(() => {
    if (mostrarRespostas && checked) {
      if (atividade.expected_answer === alternativa._id
        || atividade.expected_answer === undefined) {
        firebase.database
          .ref(`respostas-corretas/${firebase.getUser().uid}/modulos/${atividade.module}/${alternativa.activity}`)
          .set({
            resposta: alternativa._id,
          });
        setClasses("resposta-correta");
        setMessage("Sua resposta está correta.");
      } else {
        firebase.database
          .ref(`respostas-corretas/${firebase.getUser().uid}/modulos/${atividade.module}/${alternativa.activity}`)
          .remove();
        setClasses("resposta-incorreta");
        setMessage("Sua resposta está incorreta.");
      }
    } else {
      setClasses("");
      setMessage("");
    }
  }, [mostrarRespostas, alternativa, checked, atividade]);

  const handleCheck = function () {
    if (checked) {
      setSelectedOption(undefined);
    } else {
      setSelectedOption(alternativa)
    }
  }

  return (
    <>
      <div className="alternativa-container">
        <label className={
          `alternativa
        ${classes}
        ${ checked ? "checked" : ""}`

        }>
          {alternativa.location ? <img src={alternativa.location} alt="" /> : ""}
          <input
            type="radio"
            name={alternativa.activity}
            value={alternativa._id}
            checked={checked}
            disabled={mostrarRespostas}
            onChange={handleCheck}
            required
          />
          {alternativa.text}
        </label>

        {alternativa.video && alternativa.video !== "undefined"
          ? (
            <a
              href={alternativa.video}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={iconeLibras}
                alt="Vídeo em libras"
                className="icone-libras" />
            </a>
          )
          : ""
        }
      </div>
      <span>{message}</span>
    </>
  );
}
