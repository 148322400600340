import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import "./styles.css";
import UserMenu from "../../components/UserMenu";
import Footer from "../../components/Footer";
import videoUtils from "../../utils/videoUtils";

import api from "../../Services/api";

export default function Palavra(props) {
  const { id: idPalavra } = useParams();
  const [palavra, setPalavra] = useState("");

  useEffect(() => {
    api.get("dictionary/" + idPalavra).then((response) => {
      setPalavra(response.data);
    });
  }, [idPalavra]);

  return (
    <div className="container">
      <UserMenu />
      <div className="content">
        <div className="palavra-container">
          <Link to="/dicionario">
            <h2>Dicionário</h2>
          </Link>
          {palavra.location && <img src={palavra.location} alt="imagem" />}
          <h3>{palavra.word}</h3>
          {palavra.video &&
            <iframe
              title={palavra.word}
              src={videoUtils.urlToEmbed(palavra.video)}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media;"
              allowFullScreen
            ></iframe>
          }
          {palavra.meaning && <p><strong>Significado: </strong>{palavra.meaning}</p>}
        </div>
      </div>
      <Footer />
    </div>
  );
}
