function getIdFromUrl(url) {
  return url.slice(-11);
}

function urlToEmbed(url) {
  const baseURL = "https://www.youtube.com/embed/";
  const id = getIdFromUrl(url);
  return baseURL + id;
}

export default { getIdFromUrl, urlToEmbed };
