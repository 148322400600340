import React from "react";

import iconeLibras from "../../../assets/logo_DU.svg";

export default function ActivityHeader({ atividade }) {
  return (
    <div
      className="atividade-header"
      style={{
        textAlign: "center",
      }}
    >
      {atividade.statement_image && (
        <img src={atividade.statement_image} alt="" />
      )}
      {atividade.pdf && (
        <a
          className="btn-default"
          href={`${atividade.pdf}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Acesse o livro
        </a>
      )}
      <h4>
        {atividade.statement}
        {atividade.video && atividade.video !== "undefined" ? (
          <a href={atividade.video} target="_blank" rel="noopener noreferrer">
            <img
              src={iconeLibras}
              alt="Vídeo em libras"
              className="icone-libras"
            />
          </a>
        ) : (
          ""
        )}
      </h4>
    </div>
  );
}
