import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import UserMenu from "../../components/UserMenu";
import Footer from "../../components/Footer";
import Atividade from "../Atividade";
import Video from "../Partials/Video";

import api from "../../Services/api";
import "./styles.css";

import { useParams } from "react-router-dom";

export default function Modulo(props) {
  const { id: idAtividade } = useParams();
  const [atividade, setAtividade] = useState({});
  const [modulo, setModulo] = useState({});
  const [submitValue, setSubmitValue] = useState("Responder");
  const [mostrarRespostas, setMostrarRespostas] = useState(false);
  const [atividadesCorretas, setAtividadesCorretas] = useState([]);

  useEffect(() => {
    api.get(`activities/${idAtividade}`).then((response) => {
      setAtividade(response.data);
      console.log(response.data);
      api
        .get(`modules/${response.data.module}`)
        .then((response) => setModulo(response.data));
    });
  }, [idAtividade]);

  function PDFModulo() {
    //TODO: abrir PDF em modal
    if (modulo.pdf !== undefined && modulo.pdf !== "undefined") {
      return (
        <a
          className="btn-default"
          href={`${modulo.pdf}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Acesse o livro
        </a>
      );
    }
    return "";
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (submitValue === "Responder") {
      setMostrarRespostas(true);
      setSubmitValue("Refazer");
    } else {
      let message = "Deseja realmente refazer o módulo?";
      if (window.confirm(message)) {
        setMostrarRespostas(false);
        setSubmitValue("Responder");
      }
    }
  }

  return (
    <div className="container">
      <UserMenu />
      <div className="content">
        <div className="modulo-container">
          <Link to={`/modulos/${modulo._id}`}>
            <h2>Módulo: {modulo.name}</h2>
          </Link>
          {modulo.description && <h3>{modulo.description}</h3>}
          {modulo.image && <img src={`${modulo.image}`} alt="" />}
          <Video video={modulo.video} />
          <PDFModulo />
          <form onSubmit={handleSubmit}>
            <Atividade
              atividade={atividade}
              mostrarRespostas={mostrarRespostas}
              key={atividade._id}
              atividadesCorretas={atividadesCorretas}
              setAtividadesCorretas={setAtividadesCorretas}
            />

            <input type="submit" value={submitValue} className="btn-default" />

            {mostrarRespostas &&
              <Link to={`/modulos/${modulo._id}`} className="btn-back">
                Voltar
              </Link>
            }
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
