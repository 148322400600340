import React, { useCallback } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";

import firebase from "../../Services/firebase";

import "./styles.css";

import logoImg from "../../assets/logo_branca.svg";
import { TextField } from "@material-ui/core";
import { toast } from "react-toastify";

const RecuperarSenha = () => {
  const history = useHistory();

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const { email } = event.target.elements;

      try {
        await firebase.auth.sendPasswordResetEmail(email.value).then(() => {
          history.push("/login");
        });
      } catch (error) {
        toast.error("E-mail e/ou senha inválido(s).", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [history]
  );

  return (
    <div className="recuperar-container">
      <div className="content">
        <Link to="/">
          <img src={logoImg} alt="EduLibras" className="logo" />
        </Link>
        <form onSubmit={handleSubmit}>
          <h2>Recuperar senha</h2>
          <TextField type="email" name="email" label="E-mail" required />
          <button type="submit" className="btn-default">
            Enviar
          </button>
        </form>
        <Link to="/login">Voltar</Link>
      </div>
    </div>
  );
};

export default withRouter(RecuperarSenha);
