import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import "./styles.css";

import api from "../../Services/api";

import Video from "../Partials/Video";
import UserMenu from "../../components/UserMenu";
import Footer from "../../components/Footer";

export default function ItemBiblioteca() {
  const { id: idItem } = useParams("");
  const [item, setItem] = useState("");

  useEffect(() => {
    api.get("library/" + idItem).then((response) => {
      setItem(response.data);
    });
  }, [idItem]);

  function ShowItem() {
    console.log(item.type);
    switch (item.type) {
      case "video":
        return <Video video={item.location} />;
      case "music":
        return <Video video={item.location} />;
      case "book":
        return <iframe
          className="book"
          title={item.name}
          src={item.location}
        ></iframe>;
      default:
        return null;
    }
  }

  return (
    <div className="container">
      <UserMenu />
      <div className="content item-container">
        <Link to="/biblioteca">
          <h2>
            Biblioteca -
          {item.type === "video" && " Vídeo"}
            {item.type === "music" && " Música"}
            {item.type === "book" && " Livro"}
          </h2>
        </Link>
        <h3>{item.name}</h3>
        <ShowItem />
        {item.type === "book" &&
          <a
            id="btn-livro-biblioteca"
            className="btn-default"
            href={`${item.location}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Baixar livro
            </a>
        }
        {item.source && (
          <p className="source"><strong>Fonte:</strong> {item.source}</p>
        )}
      </div>
      <Footer />
    </div>
  );
}
