import React from "react";
import { Link, useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { translateAuthErrors } from "../../utils/translateErrorUtils";
import { FaGooglePlusG, FaFacebookF } from "react-icons/fa";

import "./styles.css";

import logoImg from "../../assets/logo_branca.svg";
import { toast } from "react-toastify";

export default function Acesso(props) {
  const history = useHistory();

  return (
    <div className="container acesso-container">
      <div className="content">
        <Link to="/">
          <img src={logoImg} alt="EduLibras" className="logo" />
        </Link>
        <Link to="/login" className="btn-login btn-login-default">
          Entrar com e-mail
        </Link>
        <button
          className="btn-login btn-facebook"
          onClick={() => {
            const fbAuthProvider = new firebase.auth.FacebookAuthProvider();
            firebase
              .auth()
              .signInWithPopup(fbAuthProvider)
              .then(() => {
                history.push("/");
              })
              .catch((error) => {
                let errorMessage = translateAuthErrors(error);
                toast.error(`${errorMessage}`, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          }}
        >
          <FaFacebookF size={30} className="icon" />
          Continuar com o Facebook
        </button>
        <button
          className="btn-login btn-google"
          onClick={() => {
            const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
            firebase
              .auth()
              .signInWithPopup(googleAuthProvider)
              .then(() => {
                history.push("/");
              })
              .catch((error) => {
                let errorMessage = translateAuthErrors(error);
                toast.error(`${errorMessage}`, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          }}
        >
          <FaGooglePlusG size={30} className="icon" />
          Continuar com o Google
        </button>
        <Link to="/cadastrar" className="btn-login btn-login-default">
          Cadastrar
        </Link>
      </div>
    </div>
  );
}
