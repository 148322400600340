import React from "react";

import "./styles.css";

import sobreImg from "../../assets/edu_sobre_nos.png";
import valoresImg from "../../assets/rebeca_nossos_valores.png";
import iconeLibras from "../../assets/logo_DU.svg";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import GuestMenu from "../Menu/GuestMenu";

export default function Home() {
  return (
    <div className="wrapper">
      <Header />
      <GuestMenu />
      <div className="content">
        <div className="home-container">
          <div className="about-container">
            <div className="home-item">
              <article>
                <h2>
                  Sobre nós
                  <a
                    href="https://youtu.be/Z0hz45v6BaU"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={iconeLibras}
                      alt="Vídeo em libras"
                      className="icone-libras"
                    />
                  </a>
                </h2>
                <img src={sobreImg} alt="" />
                <p>
                  O aplicativo <em>EduLibras</em>, desenvolvido durante o Mestrado no PPGEEB/CEPAE/UFG,
                  constitui-se uma das mais belas histórias que já vivi.
                  Revelou-se na sala de aula, e do amor pelo outro e no contato com o outro, pois quando eu coloquei meu coração nesse projeto acreditei que o resultado seria promissor.
                </p>
                <p>
                O meu sonho é que o aplicativo <em>EduLibras</em>, como produto educacional, socialize a Libras/Português Escrito, que essa invenção tecnológica se constitua em um importante recurso didático para o aprimoramento desse ensino e, por conseguinte, se desponte como alternativa para a transformação social e para a melhoria das interações entre as pessoas. 
                </p>
              </article>
            </div>
          </div>
          <div className="values-container">
            <div className="home-item">
              <article>
                <h2>
                  Nossos valores
                  <a
                    href="https://youtu.be/yDfTm0cfE_0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={iconeLibras}
                      alt="Vídeo em libras"
                      className="icone-libras"
                    />
                  </a>
                </h2>
                <img src={valoresImg} alt="" className="valores-image"/>
                <p>
                  <strong>MISSÃO:</strong> Oferecer conteúdo de qualidade para o
                  ensino e desenvolvimento, tanto de falantes quanto de não
                  falantes pelo ensino de Libras/Português escrito.
                </p>
                <p>
                  <strong>VISÃO:</strong> Atingir o maior número de pessoas em todas as esferas da sociedade.
                </p>
              </article>
            </div>
          </div>
          <div className="about-container">
            <div className="home-item">
              <article>
                <h2>
                  EDULIBRAS
                  <a
                    href="https://youtu.be/wrRczyDLPPQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={iconeLibras}
                      alt="Vídeo em libras"
                      className="icone-libras"
                    />
                  </a>
                </h2>
                <img src={sobreImg} alt="" />
                <p>
                  <strong>O que é:</strong> <em>EduLibras</em> é um aplicativo
                  que ensina dois idiomas específicos: Libras e Português escrito, com interação individual ou em grupo. Visa proporcionar conteúdos que ampliem o domínio da leitura e da escrita dos usuários.
                </p>
                <p>
                  <strong>Como usar:</strong> Na plataforma educacional
                  <em> EduLibras</em> os usuários acumulam pontos e assinalam
                  suas conquistas ao completar suas atividades com sucesso.
                </p>
              </article>
            </div>
          </div>
          <div className="values-container">
            <div className="home-item">
              <article>
                <h2>
                  PASSO A PASSO
                  <a
                    href="https://youtu.be/mU_PBp6xQbQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={iconeLibras}
                      alt="Vídeo em libras"
                      className="icone-libras"
                    />
                  </a>
                </h2>
                <img src={valoresImg} alt="" />
                <p>
                  <strong>Baixar e acessar:</strong> Inicialmente deve-se baixar
                  e acessar o aplicativo <em>EduLibras</em> ou a página
                  www.edulibras.com.br e clicar em entrar ou cadastrar. O
                  usuário poderá continuar a navegação usando sua conta no
                  <em> Facebook</em> ou <em>Google</em>.
                </p>
                <p>
                  <strong>Perfil:</strong> O próximo passo é criar o perfil do
                  usuário. Isso permitirá retomar as atividades do ponto em que
                  parou, podendo acessá-las posteriormente do celular, do tablet
                  ou do computador.
                </p>
                <p>
                  <strong>Módulo: </strong>Escolha o módulo 1 para iniciar as
                  atividades. São textos, vídeos e imagens que ajudarão o usuário
                  no ensino e aprendizagem de Libras/Português escrito.
                </p>
                <p>
                  <strong>Biblioteca: </strong> Na opção biblioteca, está
                  inserido todo o aporte teórico do aplicativo: imagens,
                  músicas, textos e vídeos, que o usuário poderá recorrer quando
                  precisar aprender mais conteúdo.
                </p>
                <p>
                  <strong>Dicionário: </strong>
                  No dicionário, constam os conteúdos sinalizados em
                  Libras/Português escrito, o que torna acessível a pesquisa de alfabeto, de imagens, de palavras, de numerais e de sinais.
                </p>
                <p>
                  Conforme o usuário for utilizando o <em>EduLibras</em>, poderá
                  entender a ferramenta e usá-la como recurso educacional.
                  Assim, aprender Libras/Português escrito vai se tornar mais fácil.
                </p>
              </article>
            </div>
          </div>

          <div className="about-container">
            <div className="home-item">
              <article>
                <h2>
                  EQUIPE EDULIBRAS
                  <a
                    href="https://youtu.be/bZic_bjwty4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={iconeLibras}
                      alt="Vídeo em libras"
                      className="icone-libras"
                    />
                  </a>
                </h2>
                <img src={sobreImg} alt="" />
                <p>
                  <strong>Idealização</strong>
                  <br />
                  Genessi Borba Gomes Alves Santos
                </p>
                <p>
                  <strong>Orientação no Mestrado</strong>
                  <br />
                  Profa. Dra. Deise Nanci de Castro Mesquita
                </p>
                <p>
                  <strong>Coorientação no Mestrado</strong>
                  <br />
                  Profa. Ma. Segismunda Sampaio da Silva Neta
                </p>
                <p>
                  <strong>Desenvolvimento</strong>
                  <br />
                  Level 5 – Empresa Júnior / UFG
                </p>
                <p>
                  <strong>Edição de vídeos</strong>
                  <br />
                  Dhêimy Tarllyson Santos Silva
                </p>
                <p>
                  <strong>Identidade visual</strong>
                  <br />
                  Rogério Valério Fernandes Júnior
                </p>
                <p>
                  <strong>Tradução de Voz</strong>
                  <br />
                  Raquel Silva Pereira de Araújo
                </p>
                <p>
                  <strong>Tradução em Libras</strong>
                  <br />
                  Gabriella Maria Gonçalves Ferreira
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
