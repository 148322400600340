import React from "react";
import { useHistory, Link } from "react-router-dom";
import UserMenu from "../../components/UserMenu";
import Footer from "../../components/Footer";
import Button from "@material-ui/core/Button";

import "./styles.css";

import userImgEdu from "../../assets/Edu_3x4.png";
import userImgRebeca from "../../assets/Rebeca_3x4.png";

import firebase from "../../Services/firebase";
import firebaseApp from "firebase/app";
import { toast } from "react-toastify";
import { translateAuthErrors } from "../../utils/translateErrorUtils";

const Perfil = () => {
  const user = firebase.getUser();
  const history = useHistory();

  const handleLogout = () => {
    firebase.logout().then(() => {
      history.push("/");
    });
  };

  return (
    <div className="container">
      <UserMenu />
      <div className="content perfil-container">
        <h2>Perfil</h2>
        <img
          src={user.photoURL === "edu" ? userImgEdu : userImgRebeca}
          alt="Imagem do usuário"
          className="user-img"
        />

        <h3>Olá, {user.displayName} </h3>

        <Button type="submit" component={Link} to="/perfil/editar">
          Editar perfil
        </Button>
        <Button type="submit" component={Link} to="/perfil/alterar-senha">
          Alterar senha
        </Button>

        <button
          variant="outlined"
          className="btn-login2 btn-facebook2"
          onClick={() => {
            const fbAuthProvider = new firebaseApp.auth.FacebookAuthProvider();
            user
              .linkWithPopup(fbAuthProvider)
              .then((success) => {
                toast.success(
                  "Perfil vinculado a conta do Facebook com sucesso.",
                  {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
              })
              .catch((error) => {
                const errorMessage = translateAuthErrors(error);
                toast.error(`${errorMessage}`, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          }}
        >
          Vincular conta Facebook
        </button>
        <button
          variant="outlined"
          className="btn-login2 btn-google2"
          onClick={() => {
            const googleAuthProvider = new firebaseApp.auth.GoogleAuthProvider();
            user
              .linkWithPopup(googleAuthProvider)
              .then((success) => {
                toast.success(
                  "Perfil vinculado a conta do Facebook com sucesso.",
                  {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
              })
              .catch((error) => {
                const errorMessage = translateAuthErrors(error);
                toast.error(`${errorMessage}`, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          }}
        >
          Vincular conta Google
        </button>
        <Button
          variant="outlined"
          component={Link}
          to="/perfil/vincular-email-senha"
          margin="normal"
          color="primary"
          className="btn-email-senha"
          style={{ borderRadius: 40, width: '15rem', marginBottom: 15 }}
        >
          Vincular Email e Senha
        </Button>

        <Button
          type="submit"
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleLogout}
        >
          Sair
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default Perfil;
