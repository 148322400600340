import React, { useState, useEffect } from "react";

// import "./styles.css";

import api from "../../Services/api";
import ActivityHeader from "../Partials/ActivityHeader";
import Alternativa from "../Alternativa";

export default function AtividadeObjetiva(props) {
  const { atividade, mostrarRespostas} = props;
  const [alternativas, setAlternativas] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    api.get("alternative/find/" + atividade._id).then(response => {
      setAlternativas(response.data);
    });
  }, [atividade]);

  function checked(id) {
    if (selectedOption && selectedOption._id === id){
      return true;
    }
    return false;
  }

  return (
    <>
      <ActivityHeader atividade={atividade} />
      {alternativas.map(alternativa => (
        <Alternativa
          atividade={atividade}
          alternativa={alternativa}
          mostrarRespostas={mostrarRespostas}
          key={alternativa._id}
          checked={checked(alternativa._id)}
          setSelectedOption={setSelectedOption}
        />
      ))}
    </>
  );
}
