import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

import logoImg from "../../assets/logo_branca.svg";

export default function Header() {
  return (
    <header className="header-container">
      <Link to="/"><img src={logoImg} alt="EduLibras" /></Link>
      <p>Porque se comunicar é para todos.</p>
    </header>
  );
}
