import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../Services/firebase";

import { TextField } from "@material-ui/core";

import UserMenu from "../../components/UserMenu";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";
import { translateAuthErrors } from "../../utils/translateErrorUtils";

const AlterarSenha = () => {
  const history = useHistory();
  const user = firebase.getUser();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      console.log(newPassword, confirmPassword);
      if (newPassword !== confirmPassword) {
        alert("As senhas informadas são diferentes.");
        return;
      }
      try {
        await user.updatePassword(newPassword);
        history.push("/perfil");
      } catch (error) {
        const errorMessage = translateAuthErrors(error);
        toast.error(`${errorMessage}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [newPassword, confirmPassword, user, history]
  );
  return (
    <div className="container editar-perfil-container">
      <UserMenu />
      <div className="content">
        <h2>Alterar Senha</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            type="password"
            name="newPassword"
            label="Nova senha"
            value={newPassword}
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
            fullWidth
            margin="dense"
            required
          />
          <TextField
            type="password"
            name="confirmPassword"
            label="Confirme a senha"
            value={confirmPassword}
            onChange={(event) => {
              setConfirmPassword(event.target.value);
            }}
            fullWidth
            margin="dense"
            required
          />
          <button type="submit" className="btn-default">
            Salvar
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default AlterarSenha;
