import app from 'firebase/app';
import 'firebase/auth';
import "firebase/database";

class Firebase {
  constructor() {
    app.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID
    });

    this.auth = app.auth();
    this.database = app.database();
  }

  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.auth.signOut();
  }

  async register(name, email, password, avatar='Edu') {
    console.log(email);
    await this.auth.createUserWithEmailAndPassword(email, password);
    return this.auth.currentUser.updateProfile({
      displayName: name,
      photoURL: avatar
    });
  }

  getUser() {
    return this.auth.currentUser;
  }

  isInitialized() {
    return new Promise( resolve => {
      this.auth.onAuthStateChanged(resolve);
    });
  }
}

export default new Firebase();
