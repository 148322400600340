import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "../../Services/api";

import "./styles.css";

import UserMenu from "../../components/UserMenu";
import Footer from "../../components/Footer";

export default function Dicionario() {
  const [palavras, setPalavras] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    api.get("dictionary").then((response) => {
      let list = response.data;
      list.sort((a, b) => {
        return a.word.localeCompare(b.word);
      });
      setPalavras(list);
    });
  }, []);

  function handleSearch(e) {
    e.preventDefault();
    setSearch(e.target.value);
  }

  return (
    <div className="container">
      <UserMenu />
      <div className="content">
        <div className="dicionario-container">
          <h2>Dicionário</h2>
          <input
            type="text"
            placeholder="Pesquisar"
            value={search}
            onChange={handleSearch}
          />
          <ul>
            {palavras.map((palavra) => {
              if (!search || palavra.word.toUpperCase().includes(search.toUpperCase())) {
                return (
                  <li key={palavra._id}>
                    <Link to={`/palavra/${palavra._id}`}>{palavra.word}</Link>
                  </li>
                );
              }
              return "";
            })}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
}
