import React from "react";
import videoUtils from "../../utils/videoUtils";

export default function Video(props) {
  const { video } = props;
  if (video !== undefined && (video !== "undefined") & (video !== "")) {
    console.log(video);
    return (
        <iframe
          title={video}
          src={videoUtils.urlToEmbed(video)}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media;"
          allowFullScreen
        ></iframe>
    );
  }
  return null;
}
