import React, { useState } from "react";
import firebase from "../../Services/firebase";

import "./styles.css";

import ActivityHeader from "../Partials/ActivityHeader";

export default function AtividadeDiscursiva(props) {
  const { atividade, mostrarRespostas } = props;
  const [resposta, setResposta] = useState("");
  const user = firebase.getUser();

  function handleResposta(event) {
    setResposta(event.target.value);
  }
  function verificaResposta() {
    console.log(atividade.expected_answer, resposta);
    if (
      mostrarRespostas &&
      (atividade.expected_answer === undefined ||
        atividade.expected_answer === "undefined" ||
        atividade.expected_answer === "" ||
        atividade.expected_answer === resposta)
    ) {
      firebase.database
        .ref(
          `respostas-corretas/${user.uid}/modulos/${atividade.module}/${atividade._id}`
        )
        .set({
          resposta: resposta,
        });
      return {
        class: "resposta-correta",
        message: "Sua resposta está correta.",
      };
    } else if (mostrarRespostas && atividade.expected_answer !== resposta) {
      firebase
        .database()
        .ref(
          `respostas-corretas/${user.uid}/modulos/${atividade.module}/${atividade._id}`
        )
        .remove();
      return {
        class: "resposta-incorreta",
        message: "Sua resposta está incorreta.",
      };
    }
    return {
      class: "",
      message: "",
    };
  }

  return (
    <div>
      <ActivityHeader atividade={atividade} />
      <input
        type="text"
        name="resposta"
        id={`resposta-${atividade._id}`}
        value={resposta}
        onChange={handleResposta}
        className={verificaResposta().class}
        disabled={mostrarRespostas}
        required
      />
      <span>{verificaResposta().message}</span>
    </div>
  );
}
