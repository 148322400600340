import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import firebase from "../../Services/firebase";
import api from "../../Services/api";

import "./styles.css";

import UserMenu from "../../components/UserMenu";
import Footer from "../../components/Footer";

import moduloImg from "../../assets/botao_modulo.png";
import moduloBronzeImg from "../../assets/Medalha_bronze.png";
import moduloOuroImg from "../../assets/Medalha_Ouro.png";
import moduloPrataImg from "../../assets/Medalha_Prata.png";

export default function () {
  const [modulos, setModulos] = useState([]);
  const [modulosFiltered, setModulosFiltered] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    async function loadModules() {
      const defaultModules = (await api.get("modules")).data;
      defaultModules.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      const userModules = await Promise.all(
        defaultModules.map(async (modulo) => {
          const totalAtividades = (
            await api.get(`activities/all/${modulo._id}`)
          ).data.length;

          //Obtém atividades respondidas corretamente
          let acertos = 0;
          await firebase.database
            .ref(
              `respostas-corretas/${firebase.getUser().uid}/modulos/${
                modulo._id
              }`
            )
            .once("value")
            .then((dataSnapshot) => {
              if (dataSnapshot.exists()) {
                acertos = Object.keys(dataSnapshot.val()).length;
              }
            });

          //define troféu de acordo com a quantidade de acertos
          if (acertos && acertos === totalAtividades) {
            modulo.trophy = moduloOuroImg;
          } else if (acertos >= 4) {
            modulo.trophy = moduloPrataImg;
          } else if (acertos >= 2) {
            modulo.trophy = moduloBronzeImg;
          } else {
            modulo.trophy = moduloImg;
          }
          return modulo;
        })
      );
      setModulos(userModules);
      setModulosFiltered(userModules);
    }

    loadModules();
  }, []);

  useEffect(() => {
    setModulosFiltered(
      modulos.filter((modulo) => {
        return modulo.name.toUpperCase().includes(search.toUpperCase());
      })
    );
  }, [search, modulos]);

  function handleSearch(e) {
    e.preventDefault();
    setSearch(e.target.value);
  }

  return (
    <div className="container">
      <UserMenu />
      <div className="content">
        <div className="modulos-container">
          <h2>Módulos</h2>
          <input
            type="text"
            placeholder="Pesquisar"
            value={search}
            onChange={handleSearch}
          />
          <ul>
            {modulosFiltered.map((modulo) => (
              <li key={modulo._id}>
                <Link to={`/modulos/${modulo._id}`}>
                  <img src={modulo.trophy} alt={modulo.name} />
                  <h4>{modulo.name}</h4>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
}
