import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

export default function Footer() {
  return (
    <footer className="footer-container">
      <p>Todos os direitos reservados a EduLibras.</p>
      <Link to="/politica-de-privacidade">Política de Privacidade</Link>
    </footer>
  );
}
