import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Route from './Route';

import { CircularProgress } from '@material-ui/core';

import Home from "../pages/Home";
import Modulos from "../pages/Modulos";
import Modulo from "../pages/Modulo2";
import AtividadeIndividual from "../pages/AtividadeIndividual";
import Dicionario from "../pages/Dicionario";
import Palavra from "../pages/Palavra";
import Biblioteca from "../pages/Biblioteca";
import ItemBiblioteca from "../pages/ItemBiblioteca";

import Acesso from "../pages/Acesso";
import Register from "../pages/Register";
import Login from "../pages/Login";
import Perfil from "../pages/Perfil";
import EditarPerfil from "../pages/EditarPerfil";
import RecuperarSenha from "../pages/RecuperarSenha";
import AlterarSenha from "../pages/AlterarSenha";
import PoliticaPrivacidade from "../pages/PoliticaPrivacidade";
import VincularEmailSenha from "../pages/VincularEmailSenha"

import firebase from '../Services/firebase';

export default function Routes() {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  useEffect(() => {
    firebase.isInitialized().then(val => {
      setFirebaseInitialized(val);
    });
  });

  return firebaseInitialized !== false ? (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />

        <Route exact path="/modulos" component={Modulos} isPrivate />
        <Route exact path="/modulos/:id" component={Modulo} isPrivate />
        <Route exact path="/modulos/atividade/:id" component={AtividadeIndividual} isPrivate />
        <Route path="/dicionario" component={Dicionario} isPrivate />
        <Route path="/palavra/:id" component={Palavra} isPrivate />
        <Route exact path="/biblioteca" component={Biblioteca} isPrivate />
        <Route path="/biblioteca/:id" component={ItemBiblioteca} isPrivate />

        <Route exact path="/perfil" component={Perfil} isPrivate />
        <Route exact path="/perfil/editar" component={EditarPerfil} isPrivate />
        <Route exact path="/perfil/alterar-senha" component={AlterarSenha} isPrivate />
        <Route exact path="/perfil/vincular-email-senha" component={VincularEmailSenha} isPrivate />
        <Route path="/acesso" component={Acesso} />
        <Route path="/cadastrar" component={Register} />
        <Route path="/login" component={Login} />
        <Route exact path="/recuperar-senha" component={RecuperarSenha} />

        <Route path="/politica-de-privacidade" component={PoliticaPrivacidade} />
      </Switch>
    </BrowserRouter>
  ) : <div id="loader"><CircularProgress /></div>;
}
