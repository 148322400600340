import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../Services/firebase";
import { TextField } from "@material-ui/core";

import "./styles.css";

import rebecaImg from "../../assets/Rebeca_3x4.png";
import eduImg from "../../assets/Edu_3x4.png";

import UserMenu from "../../components/UserMenu";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";
import { translateAuthErrors } from "../../utils/translateErrorUtils";

const EditarPerfil = () => {
  const history = useHistory();
  const user = firebase.getUser();
  const [avatar, setAvatar] = useState(user.photoURL);
  const [name, setName] = useState(user.displayName);
  const [email, setEmail] = useState(user.email);
  const [eduClass, setEduClass] = useState("selected");
  const [rebecaClass, setRebecaClass] = useState("");

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      try {
        await user.updateProfile({
          displayName: name,
          photoURL: avatar,
        });
        await user.updateEmail(email);
        history.push("/perfil");
      } catch (error) {
        const errorMessage = translateAuthErrors(error);
        toast.error(`${errorMessage}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [email, name, user, avatar, history]
  );

  useEffect(() => {
    if (avatar === "edu") {
      setEduClass("selected");
      setRebecaClass("");
    } else {
      setEduClass("");
      setRebecaClass("selected");
    }
  }, [avatar]);

  return (
    <div className="container editar-perfil-container">
      <UserMenu />
      <div className="content">
        <h2>Editar Perfil</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="radio"
            id="eduAvatar"
            name="avatar"
            value="edu"
            className="hidden"
          />
          <input
            type="radio"
            id="rebecaAvatar"
            name="avatar"
            value="rebeca"
            className="hidden"
          />

          <div className="profile-picture-box">
            <label
              htmlFor="eduAvatar"
              className={eduClass}
              onClick={(event) => {
                setAvatar("edu");
              }}
            >
              <img src={eduImg} alt="" className="user-img edu" />
            </label>
            <label
              htmlFor="rebecaAvatar"
              className={rebecaClass}
              onClick={(event) => {
                setAvatar("rebeca");
              }}
            >
              <img src={rebecaImg} alt="" className="user-img" />
            </label>
          </div>

          <TextField
            type="text"
            name="name"
            label="Nome"
            value={name}
            onChange={(event) => setName(event.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            type="email"
            name="email"
            label="E-mail"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            fullWidth
            required
          />

          <button type="submit" className="btn-default">
            Salvar
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default EditarPerfil;
